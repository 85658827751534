<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改分组' : '新建分组'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
    >
      <a-form-item label="站点组名称:" name="groupName">
        <a-input
          v-model:value="form.groupName"
          placeholder="请输入站点组名称"
          allow-clear
        />
      </a-form-item>
          <a-form-item label="所属运营商:" name="operatorId">
            <a-select
              v-model:value="form.operatorId"
              placeholder="请选择所属运营商"
              allow-clear
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as groupApi from '@/api/rnt/group'
import * as operatorApi from '@/api/rnt/operator'
export default {
  name: 'GroupEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data, {
        groupName: ''
      }),
      // 编辑弹窗表单验证规则
      rules: {
        groupId: [
          {
            required: true,
            message: '请输入站点组名称',
            type: 'string',
            trigger: 'blur'
          },
          {
            required: true,
            message: '请输入所属运营商',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      modules: [],
      operatorList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryOperater()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.createUserId = this.$store.state.user.user.userId
          groupApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
